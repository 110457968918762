import { Injectable } from '@angular/core';
import { HttpApiService } from '@core/services/http-api/http-api.service';
import { Observable } from 'rxjs';
import { Ordonnance } from '../../../../models/ordonnance.model';
import { FormArray, FormGroup } from '@angular/forms';
import { FacturationService } from './facturation.service';

@Injectable({
  providedIn: 'root',
})
export class OrdonnanceService {
  constructor(private readonly httpApiService: HttpApiService) {}

  public saveOrdonnances(ordonnances: Ordonnance[], idPrestation: string): Observable<Ordonnance[]> {
    return this.httpApiService.post<Ordonnance[]>(`api/back_office/ordonnance/${idPrestation}/batch`, ordonnances);
  }

  saveOrdonnance(ordonnance: Ordonnance, idPrestation: string) {
    return this.httpApiService.post<Ordonnance>(`api/back_office/ordonnance/${idPrestation}`, ordonnance);
  }

  deleteOrdonnance(idOrdonnance: string) {
    return this.httpApiService.delete(`api/back_office/ordonnance/${idOrdonnance}`);
  }

  sendOrdonnance(idOrdonnance: string, email: string, idPrestation: string): Observable<void> {
    return this.httpApiService.post<void>(`api/back_office/ordonnance/${idOrdonnance}/send/${email}/presta/${idPrestation}`);
  }

  getOrdonnancePdf(idOrdonnance: string): Observable<Blob> {
    return this.httpApiService.get(`api/back_office/ordonnance/${idOrdonnance}/pdf`, undefined, { responseType: 'arraybuffer' });
  }

  static buildOrdonnances(ordonnances: FormArray): Ordonnance[] {
    return ordonnances.controls.map(control => control.value).map(value => this.mapOrdonnance(value));
  }

  static buildOrdonnance(ordonnance: FormGroup): Ordonnance {
    return this.mapOrdonnance(ordonnance.value);
  }

  private static mapOrdonnance(value: any): Ordonnance {
    return {
      id: value.id === '' ? undefined : value.id,
      infosSupplementaires: value.infosSupplementaires === '' ? undefined : value.infosSupplementaires,
      etat: value.etatOrdonnance,
      numOrdonnance: value.numOrdonnance === '' ? undefined : value.numOrdonnance,
      produitPrescrits: value.prescription.map((produit: any, idx: number) => ({
        id: produit.id === '' ? undefined : produit.id,
        quantiteUvp: produit.quantity,
        posologie: produit.posologie,
        renouvelableFois: produit.renouvelableFois,
        delivre: produit.isDelivered,
        produit: produit.produit,
        numLot: produit.numLot,
        nom: produit.produit?.nom ? FacturationService.getProduitName(produit.produit) : produit.produitSearch,
        index: idx * 100,
      })),
    };
  }

  findOrdo(idOrdo: string) {
    return this.httpApiService.get<Ordonnance>(`api/ordonnance/${idOrdo}/public`);
  }
}
