import { TooltipPosition } from './tooltip.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'tooltip-component',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  tooltip?: string;
  left: number = 0;
  top: number = 0;

  @Input() visible = false;
  position: TooltipPosition = TooltipPosition.DEFAULT;

  constructor() {}
}
