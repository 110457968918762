import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TooltipPosition } from '../../tooltip/tooltip.model';
import { CODES_TRANSPORTEUR, HorsRdv, HorsRdvStep } from '../../../../../models/rdv.model';
import { User } from '../../../../../models/user.model';
import { TypeHorsRdv } from '../../../../../models/interaction.model';
import { ModePaiementEnum, PaiementStatus } from '../../../../../models/paiement.model';
import { IonSelect, ModalController } from '@ionic/angular';
import { PreviewHorsRdvPage } from '../../../../fiche-client/components/preview-hors-rdv/preview-hors-rdv.page';
import { Veterinaire } from '../../../../../models/admin.model';
import { HorsRdvService } from '@core/services/consultations/hors-rdv.service';
import { Browser } from 'leaflet';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import mobile = Browser.mobile;

@Component({
  selector: 'app-horsrdv',
  templateUrl: './hors-rdv.component.html',
  styleUrls: ['./hors-rdv.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HorsRdvComponent implements OnInit {
  @Input() horsRdv!: HorsRdv;
  @Input() vetos!: Veterinaire[];
  @Input() user!: User;
  @Input() idAnimalFilter?: string;
  @Input() displayClient = false;

  @ViewChild('stepSelect') stepSelect?: IonSelect;

  suiviForm?: FormGroup;
  mobile = mobile;

  isEditable: boolean | null = false;

  protected readonly position = TooltipPosition;
  codesTransporteur = CODES_TRANSPORTEUR;
  private newStep?: HorsRdvStep;

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly horsRdvService: HorsRdvService,
    private readonly cd: ChangeDetectorRef,
    private readonly fb: FormBuilder,
  ) {}

  protected readonly TypeHorsRdv = TypeHorsRdv;
  protected readonly PaiementStatus = PaiementStatus;
  protected readonly ModePaiementEnum = ModePaiementEnum;
  rdvStepSelectionnables: HorsRdvStep[] = [];
  modalSuiviOpen: boolean = false;

  ngOnInit() {
    this.isEditable = this.horsRdvService.isHorsRdvEditable(this.horsRdv, this.user);

    if (this.horsRdv.type === TypeHorsRdv.ENVOI_MEDICAMENTS) {
      this.suiviForm = this.fb.group({
        numeroSuivi: [this.horsRdv.suivi?.numeroSuivi],
        codeTransporteur: [this.horsRdv.suivi?.codeTransporteur],
        lienSuivi: [this.horsRdv.suivi?.lienSuivi, [Validators.pattern(/https:\/\/.+\...+\/.+/)]],
      });
    }
    if (this.horsRdv.structureVeterinaire && this.vetos) {
      this.vetos = this.vetos.filter(
        v =>
          v.structureVeterinaire.id === this.horsRdv.structureVeterinaire.id || v.structureVeterinaireAlternative?.id === this.horsRdv.structureVeterinaire.id,
      );
    }
    this.rdvStepSelectionnables = HorsRdvService.getStepsSelectionnables(this.horsRdv.step, this.user);
    this.cd.markForCheck();
  }

  showClient(id?: string) {
    window.open(`/fiche-client/${id}`, '_blank');
  }

  async openPreview() {
    const modal = await this.modalCtrl.create({
      component: PreviewHorsRdvPage,
      cssClass: 'modal-size',
      backdropDismiss: false,
      componentProps: {
        vetos: this.vetos,
        horsRdv: this.horsRdv,
        user: this.user,
      },
    });
    modal.onDidDismiss().then(data => {
      if (data.data) {
        this.horsRdv = data.data;
        this.cd.markForCheck();
      }
    });
    await modal.present();
  }

  updateRdvState($event: any) {
    if (this.horsRdv.step !== $event.target.value) {
      if (this.horsRdv.type === TypeHorsRdv.ENVOI_MEDICAMENTS && $event.target.value === HorsRdvStep.CLOTURE) {
        this.modalSuiviOpen = true;
        this.newStep = $event.target.value;
        this.cd.markForCheck();

        return;
      }

      this.horsRdvService.updateStep(this.horsRdv.id, $event.target.value).subscribe(() => {
        this.horsRdv = { ...this.horsRdv, step: $event.target.value };
        this.cd.markForCheck();
      });
    }
  }

  doNothing() {
    // hack pour éviter que le click sur l'accordéon ouvre le select
  }

  validerSuivi() {
    this.horsRdv = {
      ...this.horsRdv,
      step: this.newStep!,
      suivi: {
        numeroSuivi: this.suiviForm!.value.numeroSuivi,
        codeTransporteur: this.suiviForm!.value.codeTransporteur,
        lienSuivi: this.suiviForm!.value.lienSuivi,
      },
    };
    this.horsRdvService.updateHorsRdv(this.horsRdv).subscribe(() => {
      this.modalSuiviOpen = false;
      this.cd.markForCheck();
    });
  }

  closeModalSuivi() {
    this.modalSuiviOpen = false;
    if (this.stepSelect) {
      this.stepSelect.value = this.horsRdv.step;
    }
    this.cd.markForCheck();
  }
}
