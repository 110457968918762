import { Injectable } from '@angular/core';
import { HttpApiService } from '@core/services/http-api/http-api.service';
import { ModalController } from '@ionic/angular';
import { AnimalDoublon, Commune, UserDoublon } from 'app/models/admin.model';
import { Produit, SearchProduitRequest } from 'app/models/produit.model';
import { Acte, AdresseStructure, StructureVeterinaire } from 'app/models/rdv.model';
import { Observable } from 'rxjs';
import { DetailTarifPage } from '../pages/tarifs/detail-tarif/detail-tarif.page';
import { Page } from '../../../models/page.model';
import { DetailDoublonPage } from '../pages/doublons/detail-doublon/detail-doublon.page';
import { User } from '../../../models/user.model';

@Injectable({ providedIn: 'root' })
export class AdminService {
  constructor(
    private httpApiService: HttpApiService,
    private modalController: ModalController,
  ) {}

  getStructures(): Observable<StructureVeterinaire[]> {
    return this.httpApiService.get<StructureVeterinaire[]>('api/back_office/structure/');
  }

  getCommunes(idStructure: string): Observable<Commune[]> {
    return this.httpApiService.get<Commune[]>(`api/back_office/structure/${idStructure}/communes`);
  }

  getUserDoublons(): Observable<UserDoublon[]> {
    return this.httpApiService.get<UserDoublon[]>(`api/back_office/user/doublons`);
  }

  countUserDoublons(): Observable<number> {
    return this.httpApiService.get<number>(`api/back_office/user/doublons/count`);
  }

  getAnimalDoublons(): Observable<AnimalDoublon[]> {
    return this.httpApiService.get<AnimalDoublon[]>(`api/back_office/animal/doublons`);
  }

  countAnimalDoublons(): Observable<number> {
    return this.httpApiService.get<number>(`api/back_office/animal/doublons/count`);
  }

  getProduits(): Observable<Produit[]> {
    return this.httpApiService.get<Produit[]>('api/back_office/produit/all');
  }

  saveProduit(produit: Produit): Observable<Produit> {
    return this.httpApiService.post<Produit>('api/back_office/produit/', produit);
  }

  async openDetailTarif(props: Acte | Produit, type: 'acte' | 'produit', mode: 'view' | 'edit' = 'view', user: User) {
    const detail = await this.modalController.create({
      component: DetailTarifPage,
      cssClass: `detail-tarif-${type}-modal`,
      componentProps: {
        detailProps: props,
        type,
        mode,
        user,
      },
    });

    detail.present();

    return detail.onDidDismiss();
  }

  async openDetailDoublon(props: Acte | Produit, type: 'animal' | 'user') {
    const detail = await this.modalController.create({
      component: DetailDoublonPage,
      cssClass: `detail-doublon-${type}-modal`,
      backdropDismiss: false,
      componentProps: {
        detailProps: props,
        type,
      },
    });

    detail.present();

    return detail.onDidDismiss();
  }

  closeModal(data?: any) {
    this.modalController.dismiss(data);
  }

  public searchProduits(request: SearchProduitRequest): Observable<Page<Produit>> {
    if (request.page === undefined) {
      request.page = 0;
    }
    if (request.offset === undefined) {
      request.offset = 0;
    }
    if (request.size === undefined) {
      request.size = 99999;
    }

    return this.httpApiService.post(`api/back_office/produit/search-full`, request);
  }

  saveActe(acte: Acte): Observable<Acte> {
    return this.httpApiService.post<Acte>('api/back_office/reference/acte', acte);
  }

  getStructure(idStructure: string) {
    return this.httpApiService.get<StructureVeterinaire>(`api/back_office/structure/${idStructure}`);
  }

  addVetosCommune(idCommune: string, listVetos: Array<string | undefined>): Observable<any> {
    return this.httpApiService.post<any>(`api/communes/${idCommune}/updateVetos`, listVetos);
  }

  mergeAnimaux(animalDoublons: AnimalDoublon[]) {
    return this.httpApiService.post<void>(`api/back_office/animal/merge`, animalDoublons);
  }

  mergeAnimauxById(idAnimalCible: string, idAnimalSource: string) {
    return this.httpApiService.post<void>(`api/back_office/animal/merge/${idAnimalCible}/${idAnimalSource}`);
  }

  mergeUsers(userDoublons: UserDoublon[]) {
    return this.httpApiService.post<void>(`api/back_office/user/merge`, userDoublons);
  }

  addAdresseStructure(idStructure: string, adresse: AdresseStructure): Observable<AdresseStructure> {
    return this.httpApiService.post<AdresseStructure>(`api/back_office/structure/${idStructure}/adresse/add`, adresse);
  }

  setAdresseFacturationStructure(idStructure: string, idAdresseFacturation: string): Observable<AdresseStructure> {
    return this.httpApiService.post<AdresseStructure>(`api/back_office/structure/${idStructure}/adresse/facturation/${idAdresseFacturation}`);
  }

  setAdresseLivraisonStructure(idStructure: string, idAdresseLivraison: string): Observable<AdresseStructure> {
    return this.httpApiService.post<AdresseStructure>(`api/back_office/structure/${idStructure}/adresse/livraison/${idAdresseLivraison}`);
  }

  updateAdresseStructure(adresse: AdresseStructure): Observable<AdresseStructure> {
    return this.httpApiService.post<AdresseStructure>(`api/back_office/structure/adresse/update`, adresse);
  }

  deleteAdresseStructure(idAdresse: string) {
    return this.httpApiService.delete(`api/back_office/structure/adresse/${idAdresse}`);
  }

  getAllActes(): Observable<Acte[]> {
    return this.httpApiService.get(`api/back_office/reference/actes`);
  }
}
