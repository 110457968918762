import { Component, Input } from '@angular/core';
import { TooltipPosition } from '../../tooltip/tooltip.model';
import { EtatFactureEnum, Prestation, RdvDomicile, RdvState } from '../../../../../models/rdv.model';
import { User } from '../../../../../models/user.model';
import { EtatCompteRenduEnum } from '../../../../../models/compte-rendu.model';

@Component({
  selector: 'app-consultation',
  templateUrl: './consultation.component.html',
  styleUrls: ['./consultation.component.scss'],
})
export class ConsultationComponent {
  @Input() consultation!: RdvDomicile;
  @Input() user!: User;
  @Input() idAnimalFilter?: string;
  @Input() displayClient = true;

  protected readonly position = TooltipPosition;

  constructor() {}

  goToConsultation(id: string) {
    window.open('/rendezvous/' + id, '_blank');
  }

  protected readonly RdvState = RdvState;

  public hasFactureImpayee(prestation: Prestation, rdv: RdvDomicile): boolean {
    if (rdv.rdvStep === RdvState.RDV_TERMINE && prestation.factures) {
      return prestation.factures.some(facture => facture.etat !== EtatFactureEnum.PAYEE && facture.etat !== EtatFactureEnum.ENVOYEE_COMPTA);
    }

    return false;
  }

  public hasCrEnCours(prestation: Prestation, rdv: RdvDomicile): boolean {
    if (rdv.rdvStep === RdvState.RDV_TERMINE) {
      return prestation.compteRendu?.etat !== EtatCompteRenduEnum.ENVOYE;
    }

    return false;
  }

  showClient(id?: string) {
    window.open(`/fiche-client/${id}`, '_blank');
  }
}
