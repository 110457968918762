import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { EtatFactureEnum, Facture, Prestation } from '../../../../models/rdv.model';
import { EnvoiFactureComponent } from '../../../rdv/wizard/components/facturation/envoi-facture/envoi-facture.component';
import { EtatOrdonnanceEnum, Ordonnance } from '../../../../models/ordonnance.model';
import { EnvoiOrdonnanceComponent } from '../../../rdv/wizard/components/prescription/envoi-ordonnance/envoi-ordonnance.component';
import { CompteRendu, FileCR, FileCRTypeEnum } from '../../../../models/compte-rendu.model';
import { firstValueFrom } from 'rxjs';
import { PdfViewerComponent } from '../../components/pdf-viewer/pdf-viewer.component';
import { AlertController, ModalController } from '@ionic/angular';
import { CompteRenduService } from '../../../rdv/wizard/services/compte-rendu.service';
import { User, UserRole } from '../../../../models/user.model';
import { take } from 'rxjs/operators';
import { FactureService } from '../../../rdv/wizard/services/facture.service';
import { OrdonnanceService } from '../../../rdv/wizard/services/ordonnance.service';

@Component({
  selector: 'app-prestation-content',
  templateUrl: './prestation-content.component.html',
  styleUrls: ['./prestation-content.component.scss'],
})
export class PrestationContentComponent {
  @Input() presta!: Prestation;
  @Input() mail!: string;
  @Input() user!: User;

  constructor(
    private readonly modalCtrl: ModalController,
    private readonly alertController: AlertController,
    private readonly compteRenduService: CompteRenduService,
    private readonly factureService: FactureService,
    private readonly ordonnanceService: OrdonnanceService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  async sendFacture(facture: Facture) {
    const modal = await this.modalCtrl.create({
      component: EnvoiFactureComponent,
      cssClass: 'envoi-facture-modal',
      componentProps: {
        title: facture.etat === EtatFactureEnum.PAYEE ? 'Envoyer le justificatif de paiement' : `Envoyer la note d'honoraire`,
        email: this.mail,
        idFacture: facture.id,
      },
    });

    await modal.present();
  }

  async sendOrdo(ordo: Ordonnance) {
    const modal = await this.modalCtrl.create({
      component: EnvoiOrdonnanceComponent,
      cssClass: 'envoi-ordonnance-modal',
      componentProps: {
        title: ordo.etat === EtatOrdonnanceEnum.ENVOYEE ? `Renvoyer l'ordonnance` : `Envoyer l'ordonnance`,
        email: this.mail,
        idOrdonnance: ordo.id,
        idPrestation: this.presta.id,
      },
    });

    await modal.present();
  }

  async viewFile(file: FileCR) {
    let blobProps: Blob | undefined;
    if (file.mimeType === 'application/pdf' || file.fileName?.endsWith('.pdf')) {
      blobProps = await firstValueFrom(this.compteRenduService.getFileBlob(file.id!)).then(blob => new Blob([blob]));
    } else {
      this.downloadFile(file);

      return;
    }
    const modal = await this.modalCtrl.create({
      component: PdfViewerComponent,
      cssClass: 'pdf-modal-size',
      componentProps: {
        typeProps: 'fichier',
        fileProps: file,
        blobProps,
      },
    });

    await modal.present();
  }

  downloadFile(file: FileCR) {
    this.compteRenduService.downloadFile(file);
  }

  deleteFile(id: string) {
    const alert = this.alertController.create({
      header: `Voulez-vous vraiment supprimer ce fichier ?`,
      buttons: [
        {
          role: 'cancel',
          text: `Non`,
          handler: () => {},
        },
        {
          role: 'confirm',
          text: `Oui`,
          handler: () =>
            this.compteRenduService
              .deleteFile(id)
              .pipe(take(1))
              .subscribe(() => {
                this.presta.compteRendu!.files = this.presta.compteRendu!.files!.filter((file: FileCR) => file.id !== id);
                this.changeDetectorRef.markForCheck();
              }),
        },
      ],
    });
    alert.then(a => a.present());
  }

  viewPj(uriPiecesjointes: string) {
    window.open(uriPiecesjointes, '_blank');
  }

  viewCr(id: string) {
    this.compteRenduService.findPdfById(id).subscribe((pdf: any) => {
      const blob: Blob = new Blob([pdf], { type: 'application/pdf' });
      this.openModalPdf(blob, 'cr', id);
    });
  }

  async openModalPdf(blob: Blob, type: string, id?: string, facture?: Facture, cr?: CompteRendu, ordo?: Ordonnance) {
    const modal = await this.modalCtrl.create({
      component: PdfViewerComponent,
      cssClass: 'modal-size',
      componentProps: {
        blobProps: blob,
        typeProps: type,
        factureProps: facture,
        ordoProps: ordo,
        crPdfProps: cr,
        idProps: id,
      },
    });

    await modal.present();
  }

  protected readonly UserRole = UserRole;
  protected readonly FileCRTypeEnum = FileCRTypeEnum;

  viewFacture(facture: Facture) {
    this.factureService.getFacturePDF(facture.id!).subscribe(pdf => {
      const blob: Blob = new Blob([pdf], { type: 'application/pdf' });
      this.openModalPdf(blob, 'facture', facture.id, facture, undefined);
    });
  }

  viewOrdo(ordo: Ordonnance) {
    this.ordonnanceService.getOrdonnancePdf(ordo.id!).subscribe(pdf => {
      const blob: Blob = new Blob([pdf], { type: 'application/pdf' });
      this.openModalPdf(blob, 'ordo', ordo.id, undefined, undefined, ordo);
    });
  }

  protected readonly EtatFactureEnum = EtatFactureEnum;
  protected readonly EtatOrdonnanceEnum = EtatOrdonnanceEnum;
}
