import { Pipe, PipeTransform } from '@angular/core';
import { UserRole } from '../../../../models/user.model';

@Pipe({
  name: 'trigramme',
  pure: true,
})
export class TrigrammePipe implements PipeTransform {
  transform(user?: { nom?: string; prenom?: string; role?: UserRole }): string {
    if (user?.role && user?.role === UserRole.ROLE_CLIENT) {
      return 'Client';
    }
    if (user && user.nom && user.prenom) {
      return user.prenom[0] + user.nom[0] + user.nom[1];
    } else {
      return 'INCONNU';
    }
  }
}
