import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { HorsRdv, Rdv, RdvDomicile, RdvState } from 'app/models/rdv.model';
import { TooltipPosition } from '../tooltip/tooltip.model';
import { User } from '../../../../models/user.model';
import { CompteRenduService } from '../../../rdv/wizard/services/compte-rendu.service';
import { CompteRendu } from '../../../../models/compte-rendu.model';
import { ModalController } from '@ionic/angular';
import { RdvDomicileService } from '@core/services/consultations/rdv-domicile.service';
import { RdvService } from '@core/services/consultations/rdv.service';
import { Veterinaire } from '../../../../models/admin.model';

@Component({
  selector: 'consultation-list',
  templateUrl: 'consultation-list.component.html',
  styleUrls: ['consultation-list.component.scss'],
})
export class ConsultationListComponent implements OnInit, OnChanges {
  @Input() user?: User;
  @Input() idClient?: string;
  @Input() listHorsRdv: HorsRdv[] = [];
  @Input() vetos: Veterinaire[] = [];
  @Input() displayClient = false;
  readonly position = TooltipPosition;
  _listConsultation!: RdvDomicile[] | Rdv[];
  get listConsultation(): RdvDomicile[] | Rdv[] {
    return this._listConsultation;
  }

  @Input() set listConsultation(value: RdvDomicile[] | Rdv[]) {
    this._listConsultation = value;
  }

  @Input() fromCreateRdv = false;
  @Input() showVoirPlus = true;

  historique?: (CompteRendu | RdvDomicile)[];
  lastVeto?: User;
  seeAllConsultBoolean: boolean = this.showVoirPlus ? false : true;

  constructor(
    private readonly compteRenduService: CompteRenduService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly rdvService: RdvDomicileService,
    private readonly modalCtrl: ModalController,
  ) {}

  ngOnInit() {
    console.log('init consultationList Component');
  }

  ngOnChanges() {
    if (this.idClient) {
      this.compteRenduService.getStandaloneCRClient(this.idClient).subscribe(cr => {
        this.historique = this.compteRenduService.groupCrAndRdvs(cr, this._listConsultation, this.listHorsRdv);
        this.lastVeto = this.historique
          .filter(c => this.isRdvDomicile(c))
          .map(c => c as RdvDomicile)
          .filter(c => ![RdvState.ANNULEE, RdvState.RDV_ANNULE, RdvState.ABANDONNEE].includes(c.rdvStep))
          .map(rdv => rdv.consultantAssigne)
          .shift();
        this.changeDetectorRef.markForCheck();
      });
    } else {
      this.historique = this.listConsultation;
    }
  }

  seeAllConsult() {
    this.seeAllConsultBoolean = !this.seeAllConsultBoolean;
  }

  isCompteRendu(item: CompteRendu | RdvDomicile | HorsRdv): item is CompteRendu {
    return (item as CompteRendu).dateCompteRendu !== undefined;
  }

  isRdvDomicile(item: CompteRendu | RdvDomicile | HorsRdv): item is RdvDomicile {
    return !this.isCompteRendu(item) && RdvService.isRdvDomicile(item);
  }

  isHorsRdv(item: CompteRendu | RdvDomicile | HorsRdv): item is HorsRdv {
    return !this.isCompteRendu(item) && RdvService.isHorsRdv(item);
  }

  onDelete(cr: CompteRendu) {
    this.historique = this.historique!.filter(o => o.id !== cr.id);
  }

  openPreviewIfFromCreateRdv(consultation: Rdv) {
    if (this.fromCreateRdv) {
      this.rdvService.openPreviewRdvSubject$.next(consultation as RdvDomicile);
      this.modalCtrl.dismiss();
    }
  }
}
