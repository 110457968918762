import { Pipe, PipeTransform } from '@angular/core';
import { HorsRdvStep } from '../../../../models/rdv.model';

@Pipe({
  name: 'horsRdvStep',
  pure: true,
})
export class HorsRdvStepPipe implements PipeTransform {
  constructor() {}

  transform(value: HorsRdvStep | string): string {
    switch (value) {
      case HorsRdvStep.ANNULE:
        return 'Annulé';
      case HorsRdvStep.INIT:
        return 'Initialisé';
      case HorsRdvStep.PAIEMENT_OK:
        return 'Paiement validé';
      case HorsRdvStep.COMMANDE_CENTRALE:
        return 'Commandé centrale';
      case HorsRdvStep.RECEPTIONNE_SIEGE:
        return 'Réceptionné Montreuil';
      case HorsRdvStep.PAIEMENT_EN_COURS:
        return 'Paiement en cours';
      case HorsRdvStep.CLOTURE:
        return 'Clôturé';
      default:
        return '-';
    }
  }
}
