import { Injectable } from '@angular/core';
import {
  Acte,
  Client,
  CreationClientPayload,
  CreationRdvPayloadType,
  EtatFacturationEnum,
  FindRdvRequest,
  MotifAnnulation,
  RdvDomicile,
  RdvPlanning,
  RdvState,
  VerifRdvByAsv,
} from 'app/models/rdv.model';
import { map, Observable, Subject } from 'rxjs';
import { HttpApiService } from '../http-api/http-api.service';
import { Adresse, User, UserRole, VilleForm } from '../../../../models/user.model';
import { Interaction, SearchInteractionRequest, TypeRdv } from 'app/models/interaction.model';
import { Page } from 'app/models/page.model';
import { selectDeviceTokenWithCredentials } from '@core/store/selector/session.selectors';
import { switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { environment } from '../../../../../environments/environment';
import { RawResult } from 'leaflet-geosearch/lib/providers/geoApiFrProvider';
import { SearchResult } from 'leaflet-geosearch/lib/providers/provider';
import { isEmpty } from 'lodash';
import { Animal } from '../../../../models/animal.model';

@Injectable({
  providedIn: 'root',
})
export class RdvDomicileService {
  public static readonly VETOPHONIE_STATES_NON_MODIFIABLES: RdvState[] = [RdvState.TERMINEE, RdvState.ANNULEE];
  public static readonly VETOPHONIE_STATES: RdvState[] = Object.values(RdvState).filter(
    state => !state.startsWith('RDV') && state !== RdvState.INIT && state !== RdvState.REPLANIFICATION,
  );
  private readonly apiUrl = environment.API_URL;

  public openPreviewRdvSubject$: Subject<RdvDomicile> = new Subject<RdvDomicile>();

  constructor(
    private readonly httpApiService: HttpApiService,
    private readonly store: Store,
  ) {}

  findAllRdv(findRdvRequest: FindRdvRequest): Observable<Page<RdvDomicile>> {
    if (isEmpty(findRdvRequest)) {
      throw new Error('données vides');
    }

    return this.httpApiService.post<Page<RdvDomicile>>('api/back_office/rdvDomicile/find', findRdvRequest).pipe(map(data => data));
  }

  findGroupedRdv(findRdvRequest: FindRdvRequest): Observable<{ [key: string]: RdvDomicile[] }> {
    if (isEmpty(findRdvRequest)) {
      throw new Error('données vides');
    }

    return this.httpApiService.post<{ [key: string]: RdvDomicile[] }>('api/back_office/rdvDomicile/find-grouped', findRdvRequest).pipe(map(data => data));
  }

  accessRdv(id: string): Observable<RdvDomicile | null> {
    return this.httpApiService.get<RdvDomicile | null>(`api/back_office/rdvDomicile/${id}`).pipe(map(data => data));
  }

  getPostalCode(code: string): Observable<VilleForm[] | undefined> {
    return this.httpApiService.get<VilleForm[]>(`api/findCommunes/${code}`).pipe(map(codeResponse => codeResponse));
  }

  createAnimalRdv(animal: Animal, idRdv: string): Observable<Animal> {
    return this.httpApiService.post(`api/back_office/animal/${idRdv}`, animal);
  }

  updateClient(editedClient: Client): Observable<Client> {
    return this.httpApiService.post<Client>('api/back_office/client/', editedClient);
  }

  lastRdv(idClient: string): Observable<RdvDomicile | undefined> {
    return this.httpApiService.get<RdvDomicile | undefined>(`api/back_office/rdvDomicile/lastRdv/${idClient}`);
  }

  setClientRenseigne(idRdv: string, isRenseigne: boolean): Observable<void> {
    return this.httpApiService.post<void>(`api/back_office/rdvDomicile/${idRdv}/setClientRenseigne/${isRenseigne}`);
  }

  updateRdvStep(idRdv: string, rdvStep: RdvState): Observable<void> {
    return this.httpApiService.post<void>(`api/back_office/rdvDomicile/${idRdv}/updateRdvStep/${rdvStep}`);
  }

  changeActe(idRdv: string, acte: Acte): Observable<void> {
    return this.httpApiService.post(`api/back_office/rdvDomicile/${idRdv}/updateActe/${acte.id}`, acte);
  }

  public needAmendePaiement(rdv: RdvDomicile, rdvStep: RdvState | undefined, motif: string | undefined) {
    return (
      rdvStep === RdvState.RDV_ANNULE &&
      motif &&
      !['INDISPONIBILITE_VETO', 'DOUBLON', 'ERREUR_SAISIE'].includes(motif) &&
      [RdvState.RDV_CONFIRME, RdvState.RDV_EN_ROUTE, RdvState.RDV_EN_COURS].includes(rdv.rdvStep) &&
      rdv.dateConsultation &&
      rdv.consultantAssigne &&
      this.isLessThan12HoursBefore(new Date(), new Date(rdv.dateConsultation))
    );
  }

  private isLessThan12HoursBefore(dateActuelle: Date, dateConsultation?: Date): boolean {
    if (!dateConsultation) {
      return false;
    }
    const diffInMillis = dateConsultation.getTime() - dateActuelle.getTime();
    const diffInHours = diffInMillis / (1000 * 60 * 60);

    return diffInHours < 12;
  }

  updateDateConsultation(idRdv: string, date: Date | null): Observable<void> {
    return this.httpApiService.post(`api/back_office/rdvDomicile/${idRdv}/updateDateConsultation`, date);
  }

  updateConsultant(idRdv: string, idConsultant: string): Observable<void> {
    return this.httpApiService.post(`api/back_office/rdvDomicile/${idRdv}/updateConsultant/${idConsultant}`);
  }

  moveRdv(idRdv: string, idConsultant: string | null, dateConsultation: Date, rdvStep: RdvState, notifyVeto: boolean, notifyClient: boolean) {
    return this.httpApiService.post(
      `api/back_office/rdvDomicile/${idRdv}/moveRdv${
        idConsultant ? '/' + idConsultant : ''
      }/step/${rdvStep}/notifyVeto/${notifyVeto}/notifyClient/${notifyClient}`,
      dateConsultation,
    );
  }

  tempsTrajet(trajet: Adresse[]) {
    return this.httpApiService.post<number>(`api/back_office/adresse/temps-trajet`, trajet);
  }

  verifRdvByAsv(idRdv: string, postData: VerifRdvByAsv): Observable<RdvDomicile> {
    return this.httpApiService.post<RdvDomicile>(`api/back_office/rdvDomicile/${idRdv}/verifAsv`, postData);
  }

  rdvEnAttente(idRdv: string): Observable<void> {
    return this.httpApiService.post<void>(`api/back_office/rdvDomicile/${idRdv}/en-attente`);
  }

  creationRdv(rdvPayload: CreationRdvPayloadType): Observable<RdvDomicile> {
    return this.httpApiService.post<RdvDomicile>(`api/back_office/rdvDomicile/`, rdvPayload);
  }

  updateRdv(rdv: RdvDomicile): Observable<RdvDomicile> {
    return this.httpApiService.post<RdvDomicile>(`api/back_office/rdvDomicile/`, rdv);
  }

  creationClient(client: CreationClientPayload): Observable<any> {
    return this.httpApiService.post<any>(`api/back_office/user/createClient`, client);
  }

  rdvAPlacer(idRdv: string): Observable<void> {
    return this.httpApiService.post<void>(`api/back_office/rdvDomicile/${idRdv}/a-placer`);
  }

  searchInteractionRdv(interactionRequest: SearchInteractionRequest): Observable<Interaction[]> {
    return this.httpApiService.post<Interaction[]>(`api/interaction/search`, interactionRequest);
  }

  removeAnimalFromRdv(idAnimal: string, idRdv: string, deleteAnimal: boolean): Observable<void> {
    return this.httpApiService.delete<void>(`api/back_office/animal/rdv/${idRdv}/${idAnimal}?deleteAnimal=${deleteAnimal}`);
  }

  findMotifsAnnulation(): Observable<MotifAnnulation[]> {
    return this.httpApiService.get<MotifAnnulation[]>(`api/back_office/reference/motifAnnulation`);
  }

  getStepsSelectionnables(rdv: RdvPlanning, user: User): RdvState[] {
    let stepsToReturn: RdvState[] = [];
    if (!rdv.rdvStep) {
      return [];
    }
    if (!user) {
      return [rdv.rdvStep];
    }

    if (rdv.typeRdv === TypeRdv.INTERNE) {
      return [RdvState.RDV_A_CONFIRMER, RdvState.RDV_CONFIRME, RdvState.RDV_TERMINE];
    }
    // pour ne pas disparaître du planning
    const dateRdv = rdv.dateRdv;
    if (!dateRdv) {
      switch (rdv.rdvStep) {
        case RdvState.INIT:
          if ([UserRole.ROLE_ADMIN, UserRole.ROLE_BACKOFFICE].includes(user.role)) {
            stepsToReturn = [RdvState.RDV_ANNULE];
          }
          break;
        case RdvState.FROM_VETOPHONIE:
          if ([UserRole.ROLE_ADMIN, UserRole.ROLE_BACKOFFICE].includes(user.role)) {
            stepsToReturn = [RdvState.RDV_EN_ATTENTE, RdvState.RDV_ANNULE];
          }
          break;
        case RdvState.RDV_VERIFIE:
          stepsToReturn = [UserRole.ROLE_ADMIN, UserRole.ROLE_BACKOFFICE].includes(user.role)
            ? [RdvState.RDV_EN_ATTENTE, RdvState.RDV_ANNULE]
            : [RdvState.RDV_EN_ATTENTE];
          break;
        case RdvState.RDV_EN_ATTENTE:
        case RdvState.REPLANIFICATION:
          stepsToReturn = [UserRole.ROLE_ADMIN, UserRole.ROLE_BACKOFFICE].includes(user.role)
            ? [RdvState.RDV_VERIFIE, RdvState.RDV_ANNULE]
            : [RdvState.RDV_VERIFIE];
          break;
        case RdvState.RDV_ANNULE:
        case RdvState.ANNULEE:
          if (user.role === UserRole.ROLE_ADMIN) {
            stepsToReturn = [RdvState.INIT, RdvState.RDV_VERIFIE];
          }
          break;
        default:
          stepsToReturn = [RdvState.RDV_VERIFIE];
          break;
      }

      return [rdv.rdvStep, ...stepsToReturn];
    }

    switch (rdv.rdvStep) {
      case RdvState.INIT:
        if ([UserRole.ROLE_ADMIN, UserRole.ROLE_BACKOFFICE].includes(user.role)) {
          stepsToReturn = [RdvState.RDV_ANNULE];
        }
        break;
      case RdvState.FROM_VETOPHONIE:
        if ([UserRole.ROLE_ADMIN, UserRole.ROLE_BACKOFFICE].includes(user.role)) {
          stepsToReturn = [RdvState.RDV_EN_ATTENTE, RdvState.RDV_A_CONFIRMER, RdvState.RDV_CONFIRME, RdvState.RDV_ANNULE];
        }
        break;
      case RdvState.RDV_A_CONFIRMER:
        stepsToReturn = [UserRole.ROLE_ADMIN, UserRole.ROLE_BACKOFFICE].includes(user.role)
          ? [RdvState.RDV_VERIFIE, RdvState.RDV_EN_ATTENTE, RdvState.RDV_CONFIRME, RdvState.RDV_ANNULE]
          : [RdvState.RDV_VERIFIE, RdvState.RDV_EN_ATTENTE, RdvState.RDV_CONFIRME];
        break;

      case RdvState.RDV_VERIFIE:
        stepsToReturn = [UserRole.ROLE_ADMIN, UserRole.ROLE_BACKOFFICE].includes(user.role)
          ? [RdvState.RDV_A_CONFIRMER, RdvState.RDV_EN_ATTENTE, RdvState.RDV_CONFIRME, RdvState.RDV_ANNULE]
          : [RdvState.RDV_A_CONFIRMER, RdvState.RDV_EN_ATTENTE, RdvState.RDV_CONFIRME];
        break;
      case RdvState.RDV_EN_ATTENTE:
      case RdvState.REPLANIFICATION:
        stepsToReturn = [UserRole.ROLE_ADMIN, UserRole.ROLE_BACKOFFICE].includes(user.role)
          ? [RdvState.RDV_A_CONFIRMER, RdvState.RDV_VERIFIE, RdvState.RDV_CONFIRME, RdvState.RDV_ANNULE]
          : [RdvState.RDV_A_CONFIRMER, RdvState.RDV_VERIFIE, RdvState.RDV_CONFIRME];
        break;
      case RdvState.RDV_CONFIRME:
      case RdvState.CONFIRM:
        stepsToReturn = [UserRole.ROLE_ADMIN, UserRole.ROLE_BACKOFFICE].includes(user.role)
          ? [RdvState.RDV_A_CONFIRMER, RdvState.RDV_VERIFIE, RdvState.RDV_EN_ATTENTE, RdvState.RDV_EN_ROUTE, RdvState.RDV_EN_COURS, RdvState.RDV_ANNULE]
          : [RdvState.RDV_EN_ROUTE, RdvState.RDV_EN_COURS];
        break;
      case RdvState.RDV_EN_ROUTE:
        stepsToReturn = [UserRole.ROLE_ADMIN, UserRole.ROLE_BACKOFFICE].includes(user.role)
          ? [RdvState.RDV_EN_ATTENTE, RdvState.RDV_CONFIRME, RdvState.RDV_EN_COURS, RdvState.RDV_ANNULE]
          : [RdvState.RDV_EN_COURS];
        break;
      case RdvState.RDV_EN_COURS:
      case RdvState.PAIEMENT_OK:
      case RdvState.PAIEMENT_EN_COURS:
        stepsToReturn = [UserRole.ROLE_ADMIN, UserRole.ROLE_BACKOFFICE].includes(user.role)
          ? [RdvState.RDV_EN_ATTENTE, RdvState.RDV_CONFIRME, RdvState.RDV_EN_ROUTE, RdvState.RDV_TERMINE]
          : [RdvState.RDV_TERMINE];
        break;
      case RdvState.RDV_TERMINE:
        if (
          rdv.typeRdv === TypeRdv.DOMICILE &&
          (rdv as RdvDomicile).prestations?.flatMap(p => p.facturations || [])?.some(f => f.etat !== EtatFacturationEnum.PAYEE)
        ) {
          stepsToReturn = [UserRole.ROLE_ADMIN, UserRole.ROLE_BACKOFFICE].includes(user.role)
            ? [RdvState.RDV_EN_ATTENTE, RdvState.RDV_CONFIRME, RdvState.RDV_EN_ROUTE, RdvState.RDV_EN_COURS]
            : [];
          break;
        } else {
          stepsToReturn = [UserRole.ROLE_ADMIN, UserRole.ROLE_BACKOFFICE].includes(user.role)
            ? [RdvState.RDV_EN_ATTENTE, RdvState.RDV_CONFIRME, RdvState.RDV_EN_ROUTE, RdvState.RDV_EN_COURS, RdvState.RDV_CLOTURE]
            : [];
          break;
        }
      case RdvState.RDV_CLOTURE:
      case RdvState.TERMINEE:
        stepsToReturn = [UserRole.ROLE_ADMIN, UserRole.ROLE_BACKOFFICE].includes(user.role)
          ? [RdvState.RDV_EN_ATTENTE, RdvState.RDV_CONFIRME, RdvState.RDV_EN_ROUTE, RdvState.RDV_EN_COURS, RdvState.RDV_TERMINE]
          : [];
        break;
      case RdvState.RDV_ANNULE:
      case RdvState.ANNULEE:
        stepsToReturn = user.role === UserRole.ROLE_ADMIN ? [RdvState.INIT, RdvState.RDV_VERIFIE] : [];
        break;
      default:
        stepsToReturn = [];
    }

    if (user.role === UserRole.ROLE_VETERINAIRE && this.isVetoCreator(rdv, user) && ![RdvState.TERMINEE, RdvState.RDV_CLOTURE].includes(rdv.rdvStep)) {
      stepsToReturn = [RdvState.RDV_ANNULE, ...stepsToReturn];
    }

    return [rdv.rdvStep, ...stepsToReturn];
  }

  private isVetoCreator(rdv: RdvPlanning, user: User) {
    return rdv.auteurId && rdv.auteurId === user.id;
  }

  public static sortLeafletLocations(locations: SearchResult<RawResult>[], adresseRdv: Adresse): SearchResult<RawResult>[] {
    return locations.sort((l1, l2) => {
      let poids1 = 0;
      let poids2 = 0;
      if (l1.raw.properties.postcode.substring(0, 2) === adresseRdv.codePostal?.substring(0, 2)) {
        poids1++;
      }
      if (l2.raw.properties.postcode.substring(0, 2) === adresseRdv.codePostal?.substring(0, 2)) {
        poids2++;
      }
      if (l1.raw.properties.score >= l2.raw.properties.score) {
        poids1++;
      } else {
        poids2++;
      }

      return poids1 > poids2 ? -1 : 1;
    });
  }

  unsusbSse(idStructure: string) {
    return this.httpApiService.get<void>('api/back_office/rdvDomicile/sseUnsubscribe/' + idStructure + '/' + window.name);
  }

  subscribeSse(idStructure: string) {
    return this.store.select(selectDeviceTokenWithCredentials).pipe(
      take(1),
      switchMap(({ credentials }) => {
        const endpoint = `${this.apiUrl}/api/back_office/rdvDomicile/sseDemande/${idStructure}/${window.name}/${credentials?.access_token}`;

        return this.httpApiService.getAsStream<string>(endpoint);
      }),
    );
  }

  static isFacturationEditable(rdv: RdvDomicile, currentUser: User): boolean {
    if (!rdv) {
      return false;
    }
    const isRdvVetophonie = RdvDomicileService.VETOPHONIE_STATES_NON_MODIFIABLES.includes(rdv.rdvStep);

    if (isRdvVetophonie || !currentUser) {
      return false;
    }

    if (currentUser.role === UserRole.ROLE_ADMIN) {
      return true;
    }

    if (currentUser.role === UserRole.ROLE_BACKOFFICE) {
      return ![RdvState.ABANDONNEE, RdvState.RDV_ANNULE].includes(rdv.rdvStep);
    }

    if (currentUser.role === UserRole.ROLE_VETERINAIRE && rdv.consultantAssigne?.id === currentUser.id) {
      return ![RdvState.ABANDONNEE, RdvState.RDV_ANNULE].includes(rdv.rdvStep);
    }

    return false;
  }

  static isOrdoEditable(rdv: RdvDomicile, currentUser: User): boolean {
    if (!rdv) {
      return false;
    }
    const isRdvVetophonie = RdvDomicileService.VETOPHONIE_STATES_NON_MODIFIABLES.includes(rdv.rdvStep);

    if (isRdvVetophonie || !currentUser) {
      return false;
    }

    if (currentUser.role === UserRole.ROLE_ADMIN) {
      return true;
    }

    if (currentUser.role === UserRole.ROLE_BACKOFFICE) {
      return false;
    }

    if (currentUser.role === UserRole.ROLE_VETERINAIRE && rdv.consultantAssigne?.id === currentUser.id) {
      return ![RdvState.ABANDONNEE, RdvState.RDV_ANNULE].includes(rdv.rdvStep);
    }

    return false;
  }
}
