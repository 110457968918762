import { Pipe, PipeTransform } from '@angular/core';
import { TypeRappel } from '../../../../models/user.model';

@Pipe({
  name: 'typeRappelStr',
  pure: true,
})
export class TypeRappelPipe implements PipeTransform {
  constructor() {}

  transform(value: TypeRappel | string): string {
    switch (value) {
      case TypeRappel.RAPPEL_CLIENT:
        return 'Rappel client';
      case TypeRappel.PRISE_NOUVELLES:
        return 'Prise de nouvelles';
      case TypeRappel.VACCIN:
        return 'Rappel vaccinal';
      case TypeRappel.AUTRE:
        return 'Autre';
      case TypeRappel.CONDOLEANCE:
        return 'Condoléances';
      default:
        return '-';
    }
  }
}
