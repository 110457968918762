import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Animal } from 'app/models/animal.model';
import { Acte, Client, FindRdvRequest, RdvDomicile, RdvPlanning, RdvState } from 'app/models/rdv.model';
import { ModePaiementEnum, Paiement } from '../../../../models/paiement.model';
import { User } from '../../../../models/user.model';
import { TypeRdv } from '../../../../models/interaction.model';

export const RdvDomicileAction = createActionGroup({
  source: 'RendezVous',
  events: {
    'load all rendezvous': props<{ rdvParams: FindRdvRequest }>(),
    'load all rendezvous success': props<{ allRdv: RdvPlanning[] }>(),
    checkRdvEditable: props<{ rdv: RdvDomicile; currentUser: User }>(),
    checkRdvEditableSuccess: props<{ editable: boolean }>(),
    checkFactuEditable: props<{ rdv: RdvDomicile; currentUser: User }>(),
    checkFactuEditableSuccess: props<{ editable: boolean }>(),
    updateRdvState: props<{ id: string; typeRdv: TypeRdv; rdvState: RdvState }>(),
    updateRdvStateSuccess: props<{ id: string; rdvState: RdvState }>(),
    updateRdv: props<{ rdv: RdvDomicile }>(),
    updateRdvSuccess: props<{ rdv: RdvDomicile }>(),
    'access rendezvous': props<{ rendezvousId: string }>(),
    'access rendezvous success': props<{ activeRendezvous: RdvDomicile }>(),
    'reset active rendezvous': emptyProps(),
    'Change acte consultation': props<{ idRdv: string; acte: Acte }>(),
    changeacteconsultationsuccess: props<{ idRdv: string; acte: Acte }>(),
    //ANIMAL
    addAnimal: props<{ animalData: Animal }>(),
    'Add Animal Success': emptyProps(),
    editAnimal: props<{ animalUpdate: Animal }>(),
    'Update animal Success': props<{ updatedAnimal: Animal }>(),
    removeAnimalFromRdv: props<{ idAnimal: string; idRdv: string; deleteAnimal: boolean }>(),
    removeAnimalFromRdvSuccess: props<{ idAnimal: string; idRdv: string; deleteAnimal: boolean }>(),
    'create animal rendezvous': props<{ animal: Animal; idRdv: string }>(),
    'create animal rendezvous success': props<{ animalCreated: Animal }>(),
    //CLIENT
    'Get animal list client': props<{ idClient: string }>(),
    'Get animal list client success': props<{ animalClientList: Animal[] }>(),
    'Get animal list load success': emptyProps(),
    'change active animal': props<{ changedAnimal: Animal | null }>(),
    editClient: props<{ client: Client }>(),
    'Edit Client Success': props<{ updatedClient: Client }>(),
    setclientstep: props<{ idRdv: string; isRenseigne: boolean }>(),
    setclientstepsuccess: emptyProps(),
    // PAIEMENT
    ajoutpaiement: props<{ idRdv: string; montant: number; modePaiement: ModePaiementEnum }>(),
    ajoutpaiementsuccess: props<{ paiement: Paiement }>(),
    reloadpaiements: props<{ idRdv: string }>(),
    reloadpaiementssuccess: props<{ paiements: Paiement[] }>(),
    deletepaiement: props<{ idRdv: string; idPaiement: string }>(),
    deletepaiementsuccess: props<{ idRdv: string; idPaiement: string }>(),
    reloadActiveRdv: emptyProps(),
  },
});
