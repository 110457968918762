<div class="formulaire historique">
  <ion-text>
    <h2>Historique</h2>
  </ion-text>

  <ion-row>
    <ion-col size="12">
      <div class="ion-text-center" *ngIf="!historique?.length">
        <ion-text color="grey400">
          <p>Aucune consultation</p>
        </ion-text>
      </div>
      <div class="download-div">
        <ion-button color="blue100" class="download-btn" (click)="addComplement()" expand="block">
          <ion-icon src="/assets/attach.svg" slot="start"></ion-icon>
          <ion-label color="grey900">Ajouter un complément</ion-label>
        </ion-button>
        <ion-button color="blue100" class="download-btn" (click)="exportDossierMedical()" expand="block">
          <ion-icon name="share-social-outline" slot="start" color="blue600"></ion-icon>
          <ion-label color="grey900">Exporter dossier médical</ion-label>
        </ion-button>
      </div>
    </ion-col>
  </ion-row>
  <ion-accordion-group *ngIf="historique?.length">
    <div *ngFor="let crOrRdv of historique; let idx = index" class="cr-item">
      <ng-container *ngIf="isCompteRendu(crOrRdv)" [ngTemplateOutlet]="crTemplate" [ngTemplateOutletContext]="{ cr: crOrRdv }"></ng-container>
      <ng-container
        *ngIf="isRdvDomicile(crOrRdv) && (!idRdvFilter || idRdvFilter !== crOrRdv.id)"
        [ngTemplateOutlet]="rdvTemplate"
        [ngTemplateOutletContext]="{ rdv: crOrRdv }"
      ></ng-container>
      <ng-container *ngIf="isHorsRdv(crOrRdv)" [ngTemplateOutlet]="horsRdvContent" [ngTemplateOutletContext]="{ horsRdv: crOrRdv }"></ng-container>
    </div>
  </ion-accordion-group>
</div>
<!-- Standalone CR -->
<ng-template #crTemplate let-cr="cr">
  <app-standalone-cr-view [cr]="cr" [user]="user" [lastVeto]="lastVeto" (deleted)="onDelete(cr)"></app-standalone-cr-view>
</ng-template>
<ng-template #rdvTemplate let-rdv="rdv">
  <app-consultation [user]="user" [consultation]="rdv" [idAnimalFilter]="idAnimal" [displayClient]="false"></app-consultation>
</ng-template>
<ng-template #horsRdvContent let-horsRdv="horsRdv">
  <app-horsrdv *ngIf="user" [user]="user" [horsRdv]="horsRdv" [vetos]="vetos"></app-horsrdv>
</ng-template>
