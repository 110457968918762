import { Injectable } from '@angular/core';
import { RdvDomicileService } from '@core/services/consultations/rdv-domicile.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Animal } from 'app/models/animal.model';
import { RdvDomicile, RdvInterne, RdvPlanning } from 'app/models/rdv.model';
import { combineLatestWith, defaultIfEmpty, EMPTY, forkJoin, map, skipWhile, switchMap, tap } from 'rxjs';
import { RdvDomicileAction } from '../actions/rdv-domicile.action';
import { ReferenceService } from '../../../rdv/wizard/services/reference.service';
import { PaiementService } from '../../../rdv/wizard/services/paiement.service';
import { Store } from '@ngrx/store';
import { selectUser } from '@core/store/selector/session.selectors';
import { selectActiveRendezvous } from '@core/store/selector/rendezvous.selector';
import { catchError, filter, take } from 'rxjs/operators';
import { SavingService } from '@core/services/saving/saving.service';
import { AnimalService } from '../../../rdv/wizard/services/animal.service';
import { NavController } from '@ionic/angular';
import { RdvService } from '@core/services/consultations/rdv.service';
import { RdvInterneService } from '@core/services/consultations/rdv-interne.service';
import { TypeRdv } from '../../../../models/interaction.model';

@Injectable()
export class RdvDomicileEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly rdvDomicileService: RdvDomicileService,
    private readonly rdvInterneService: RdvInterneService,
    private readonly rdvService: RdvService,
    private readonly animalService: AnimalService,
    private readonly store: Store,
    private readonly paiementService: PaiementService,
    private readonly navCtrl: NavController,
    private readonly referenceService: ReferenceService,
    private readonly savingService: SavingService,
  ) {}

  loadAllRdv$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RdvDomicileAction.loadAllRendezvous),
      switchMap(action => {
        return forkJoin([
          this.rdvDomicileService.findAllRdv(action.rdvParams).pipe(
            map(data => data.elements || []),
            defaultIfEmpty([]),
          ),
          this.rdvInterneService.findAllRdv(action.rdvParams).pipe(
            map(data => data.elements || []),
            defaultIfEmpty([]),
          ),
        ]).pipe(
          map(([rdvDomicile, rdvInterne]) => [...rdvDomicile, ...rdvInterne]),
          map((rdvs: (RdvDomicile | RdvInterne)[]) => {
            return RdvDomicileAction.loadAllRendezvousSuccess({
              allRdv: rdvs
                .filter(rdv => !!rdv)
                .map(r => RdvService.rdvToRdvPlanning(r)!)
                .sort((a: RdvPlanning, b: RdvPlanning) => {
                  const date1 = new Date(a.dateRdv ? a.dateRdv : a.dateSouhaitee!);
                  const date2 = new Date(b.dateRdv ? b.dateRdv : b.dateSouhaitee!);

                  return date1.getTime() - date2.getTime();
                }),
            });
          }),
        );
      }),
    );
  });

  accessRdv$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RdvDomicileAction.accessRendezvous),
      switchMap(action => {
        return this.rdvDomicileService.accessRdv(action.rendezvousId).pipe(
          tap((rdv: RdvDomicile | null) => {
            if (rdv === null) {
              this.navCtrl.navigateRoot('/');
            }
          }),
          filter(rdv => !!rdv),
          combineLatestWith(this.store.select(selectUser).pipe(skipWhile(user => !user?.id))),
          // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
          switchMap(data => [
            RdvDomicileAction.checkrdveditable({ rdv: data[0]!, currentUser: data[1]! }),
            RdvDomicileAction.checkfactueditable({ rdv: data[0]!, currentUser: data[1]! }),
            RdvDomicileAction.accessRendezvousSuccess({ activeRendezvous: data[0]! }),
          ]),
        );
      }),
    );
  });

  checkrdveditable$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RdvDomicileAction.checkrdveditable),
      map(action => {
        return RdvDomicileAction.checkrdveditablesuccess({
          editable: RdvService.isRdvEditable(RdvService.rdvToRdvPlanning(action.rdv)!, action.currentUser),
        });
      }),
    );
  });

  checkfactueditable$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RdvDomicileAction.checkfactueditable),
      map(action => {
        return RdvDomicileAction.checkfactueditablesuccess({ editable: RdvDomicileService.isFacturationEditable(action.rdv, action.currentUser) });
      }),
    );
  });
  updateRdvState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RdvDomicileAction.updaterdvstate),
      switchMap(action => {
        return this.rdvService
          .updateRdvStep(action.id, action.typeRdv, action.rdvState)
          .pipe(map(() => RdvDomicileAction.updaterdvstatesuccess({ id: action.id, rdvState: action.rdvState })));
      }),
    );
  });
  updateRdvStateSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RdvDomicileAction.updaterdvstatesuccess),
      switchMap(() => this.store.select(selectActiveRendezvous).pipe(combineLatestWith(this.store.select(selectUser)))),
      filter(data => !!data[0] && !!data[1]),
      take(1),
      map(data => RdvDomicileAction.checkrdveditable({ rdv: data[0]!, currentUser: data[1]! })),
    );
  });
  updateRdv$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RdvDomicileAction.updaterdv),
      switchMap(action => {
        return this.rdvDomicileService.updateRdv(action.rdv).pipe(map(rdv => RdvDomicileAction.updaterdvsuccess({ rdv })));
      }),
    );
  });

  changeCategoryConsultation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RdvDomicileAction.changeActeConsultation),
      switchMap(action => {
        return this.rdvDomicileService
          .changeActe(action.idRdv, action.acte)
          .pipe(map(() => RdvDomicileAction.changeacteconsultationsuccess({ idRdv: action.idRdv, acte: action.acte })));
      }),
    );
  });

  changeCategoryConsultationSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RdvDomicileAction.changeacteconsultationsuccess),
        tap(() => console.log('changecategoryconsultationsuccess')),
      );
    },
    { dispatch: false },
  );

  //ANIMAL EFFECTS

  editAnimal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RdvDomicileAction.editanimal),
      tap(() => this.savingService.increment()),
      switchMap(action => {
        return this.animalService.editAnimal(action.animalUpdate).pipe(
          map(updatedAnimal => {
            return RdvDomicileAction.updateAnimalSuccess({ updatedAnimal });
          }),
          catchError(() => {
            this.savingService.decrement();

            return EMPTY;
          }),
        );
      }),
    );
  });

  removeanimalfromrdv$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RdvDomicileAction.removeanimalfromrdv),
      switchMap(action => {
        return this.rdvDomicileService.removeAnimalFromRdv(action.idAnimal, action.idRdv, action.deleteAnimal).pipe(
          map(() => {
            return RdvDomicileAction.removeanimalfromrdvsuccess(action);
          }),
        );
      }),
    );
  });

  addAnimal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RdvDomicileAction.addanimal),
      switchMap(action => {
        return this.animalService.addAnimal({ data: action.animalData }).pipe(
          map(() => {
            return RdvDomicileAction.addAnimalSuccess();
          }),
        );
      }),
    );
  });

  addAnimalSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RdvDomicileAction.addAnimalSuccess),
        tap(() => {
          {
            console.log('Animal Ajouté');
          }
        }),
      );
    },
    { dispatch: false },
  );

  editAnimalSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RdvDomicileAction.updateAnimalSuccess),
        tap(() => {
          console.log('Modifications réussies');
          this.savingService.decrement();
        }),
      );
    },
    { dispatch: false },
  );

  createAnimal = createEffect(() => {
    return this.actions$.pipe(
      ofType(RdvDomicileAction.createAnimalRendezvous),
      tap(() => this.savingService.increment()),
      switchMap(action => {
        return this.rdvDomicileService.createAnimalRdv(action.animal, action.idRdv).pipe(
          map(() => {
            this.savingService.decrement();

            return RdvDomicileAction.accessRendezvous({ rendezvousId: action.idRdv });
          }),
          catchError(() => {
            this.savingService.decrement();

            return EMPTY;
          }),
        );
      }),
    );
  });

  // CLIENT EFFECTS

  editClient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RdvDomicileAction.editclient),
      tap(() => this.savingService.increment()),
      switchMap(action => {
        return this.rdvDomicileService.updateClient(action.client).pipe(
          map(updatedClient => {
            return RdvDomicileAction.editClientSuccess({ updatedClient });
          }),
          catchError(() => {
            this.savingService.decrement();

            return EMPTY;
          }),
        );
      }),
    );
  });

  editClientSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RdvDomicileAction.editClientSuccess),
        tap(() => {
          console.log('Edit Client Success');
          this.savingService.decrement();
        }),
      );
    },
    { dispatch: false },
  );

  getAnimalListClient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RdvDomicileAction.getAnimalListClient),
      switchMap(action => {
        return this.animalService.getUserAnimals(action.idClient).pipe(
          map((animalList: Animal[]) => {
            return RdvDomicileAction.getAnimalListClientSuccess({ animalClientList: animalList });
          }),
        );
      }),
    );
  });
  setClientStep$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RdvDomicileAction.setclientstep),
      switchMap(action =>
        this.rdvDomicileService
          .setClientRenseigne(action.idRdv, action.isRenseigne)
          .pipe(map(() => RdvDomicileAction.accessRendezvous({ rendezvousId: action.idRdv }))),
      ),
    );
  });

  setClientStepSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RdvDomicileAction.setclientstepsuccess),
        tap(() => {
          console.log('Set Client Step Success');
        }),
      );
    },
    { dispatch: false },
  );
  ajoutpaiement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RdvDomicileAction.ajoutpaiement),
      switchMap(action =>
        this.paiementService
          .ajoutPaiement(action.idRdv, action.montant, action.modePaiement)
          .pipe(map(paiement => RdvDomicileAction.ajoutpaiementsuccess({ paiement }))),
      ),
    );
  });

  ajoutpaiementsuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RdvDomicileAction.ajoutpaiementsuccess),
        tap(() => {
          console.log('Ajout paiement success');
        }),
      );
    },
    { dispatch: false },
  );
  reloadpaiements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RdvDomicileAction.reloadpaiements),
      switchMap(action =>
        this.paiementService.getPaiementsByIdRdvDomicile(action.idRdv).pipe(map(paiements => RdvDomicileAction.reloadpaiementssuccess({ paiements }))),
      ),
    );
  });

  reloadpaiementssuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RdvDomicileAction.reloadpaiementssuccess),
        tap(() => {
          console.log('reload paiements success');
        }),
      );
    },
    { dispatch: false },
  );

  reloadActiveRdv$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RdvDomicileAction.reloadactiverdv),
        switchMap(() => this.store.select(selectActiveRendezvous).pipe(take(1))),
        map(activeRdv => RdvDomicileAction.accessRendezvous({ rendezvousId: activeRdv!.id })),
      );
    },
    { dispatch: true },
  );

  deletepaiement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RdvDomicileAction.deletepaiement),
      switchMap(action =>
        this.paiementService
          .deletePaiement(action.idRdv, TypeRdv.DOMICILE, action.idPaiement)
          .pipe(map(() => RdvDomicileAction.deletepaiementsuccess({ idRdv: action.idRdv, idPaiement: action.idPaiement }))),
      ),
    );
  });

  deletepaiementsuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RdvDomicileAction.deletepaiementsuccess),
        tap(() => {
          console.log('Delete paiement success');
        }),
      );
    },
    { dispatch: false },
  );
}
