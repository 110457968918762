import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Animal, AnimalWithPicture, MotifSeparation } from '../../../../models/animal.model';
import { HttpApiService } from '@core/services/http-api/http-api.service';

@Injectable({ providedIn: 'root' })
export class AnimalService {
  constructor(private readonly httpApiService: HttpApiService) {}

  getUserAnimals(id: string): Observable<Animal[]> {
    return this.httpApiService.get<Animal[]>(`api/back_office/animal/utilisateur/${id}`);
  }

  getAnimal(idAnimal: string): Observable<Animal> {
    return this.httpApiService.get<Animal>(`api/back_office/animal/${idAnimal}`);
  }

  getUserAnimalsWithPicture(id: string | undefined): Observable<AnimalWithPicture> {
    return this.httpApiService.get<AnimalWithPicture>(`api-particuliers/animal/utilisateur/${id}/withPicture`);
  }

  editAnimal(data: Animal): Observable<Animal> {
    return this.httpApiService.post('api/back_office/animal/', data);
  }

  disabledAnimal(dateSeparation: Date, motif: MotifSeparation, idAnimal: string): Observable<void> {
    return this.httpApiService.post('api-particuliers/animal/disabled-ihm', {
      dateSeparation: dateSeparation,
      motifSeparation: motif.code,
      idAnimal: idAnimal,
    });
  }

  countDoublonsAnimal(nom: string, dateNaissance: string): Observable<number> {
    return this.httpApiService.get<number>(`api/back_office/animal/doublons/count/${nom}/${dateNaissance}`);
  }

  countAnimauxForClient(id: string) {
    return this.httpApiService.get<number>(`api/back_office/animal/count/user/${id}`);
  }

  addAnimal(animalData: any): Observable<Animal> {
    return this.httpApiService.post<Animal>('api/back_office/animal/', animalData);
  }

  changeUser(animal: Animal, newUserId: string): Observable<void> {
    return this.httpApiService.post<void>(`api/back_office/animal/updateUser/${newUserId}`, animal);
  }

  hasRdv(idAnimal: string): Observable<boolean> {
    return this.httpApiService.get<boolean>(`api/back_office/animal/${idAnimal}/hasRdv`);
  }
}
