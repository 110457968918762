import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Animal } from 'app/models/animal.model';
import { EChartsOption } from 'echarts';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { Poids } from 'app/models/compte-rendu.model';
import { selectUser } from '@core/store/selector/session.selectors';
import { User } from 'app/models/user.model';
import { clientActions } from '@core/store/actions/clients.actions';
import { selectListPoids } from '@core/store/selector/clients.selector';
import { AlertController, ModalController } from '@ionic/angular';

@Component({
  selector: 'poids-component',
  templateUrl: 'poids.component.html',
  styleUrls: ['poids.component.scss'],
})
export class PoidsComponent implements OnInit, OnDestroy {
  @Input() activeAnimal!: Animal | null;
  @Input() showCourbe: boolean = false;
  @Input() editPoids: boolean = false;
  form: FormGroup;
  user$: Observable<User | undefined>;
  userRole: string | undefined = '';
  listPoids$: Observable<Poids[] | undefined>;
  loadingChart = false;
  chartOption: EChartsOption = {};
  lastPoids?: Poids;
  destroy$: Subject<void>;

  constructor(
    private readonly fb: FormBuilder,
    private readonly datePipe: DatePipe,
    private readonly store: Store,
    private readonly modalCtrl: ModalController,
    private readonly alertController: AlertController,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    this.destroy$ = new Subject();
    this.user$ = this.store.select(selectUser).pipe(distinctUntilChanged(), takeUntil(this.destroy$));
    this.listPoids$ = this.store.select(selectListPoids).pipe(distinctUntilChanged(), takeUntil(this.destroy$));
    this.form = this.fb.group({
      poids: [0, [Validators.required]],
      dateMesure: [this.datePipe.transform(new Date(), 'yyyy-MM-dd'), [Validators.required]],
    });
  }

  ngOnInit() {
    this.loadingChart = true;
    if (this.activeAnimal !== null) {
      this.store.dispatch(clientActions.getPoids({ idAnimal: this.activeAnimal.id }));
    }
    this.user$.pipe(takeUntil(this.destroy$)).subscribe(user => {
      this.userRole = user?.role;
    });

    this.listPoids$.pipe(takeUntil(this.destroy$)).subscribe(poids => {
      if (poids && poids.length > 0) {
        this.lastPoids = poids[0];

        const processedData = poids
          .filter(p => !!p.datePesee)
          .map(el => ({
            value: [new Date(el.datePesee!).getTime(), el.poids],
          }))
          .reverse();

        this.chartOption = {
          title: {
            textStyle: {
              fontSize: '1.75rem',
            },
            subtextStyle: {
              fontSize: '1rem',
            },
            left: 'center',
          },
          animation: false,
          xAxis: {
            type: 'time',
            axisLabel: {
              hideOverlap: true,
              formatter: (value: number) => {
                return this.datePipe.transform(value, 'dd/MM/YY')!;
              }
            }
          },
          yAxis: {
            type: 'value',
            name: 'kg',
          },
          tooltip: {
            trigger: 'axis',
            formatter: (params: any) => {
              const date = this.datePipe.transform(params[0].value[0], 'dd/MM/yyyy');

              return `<b>${date}</b> : ${params[0].value[1]} kg`;
            },
          },
          series: [
            {
              data: processedData,
              type: 'line',
              symbolSize: 8,
            },
          ],
        };
        this.changeDetectorRef.markForCheck();
        this.loadingChart = false;
      } else {
        this.lastPoids = undefined;
        this.chartOption = {
          title: {
            // text: 'Non renseigné',
            // subtext: 'Poids en kg',
            textStyle: {
              fontSize: '1.75rem',
            },
            subtextStyle: {
              fontSize: '1rem',
            },
            left: 'center',
          },
          grid: {
            show: true,
          },
          xAxis: {
            type: 'category',
            data: ['Aucune donnée'],
          },
          yAxis: {
            type: 'value',
            name: 'kg',
          },
          tooltip: {
            trigger: 'item',
            showDelay: 0,
            transitionDuration: 0.2,
            formatter: function (params: any) {
              return `<b>${params['name']}</b> : ${params['value']} kg`;
            },
          },
          series: [
            {
              data: [''],
              type: 'line',
              connectNulls: true,
            },
          ],
        };
        this.loadingChart = false;
        this.changeDetectorRef.markForCheck();
      }
    });
  }

  addPoids() {
    const objPoids: Poids = {
      poids: this.form.value.poids,
      role: this.userRole,
      datePesee: this.datePipe.transform(this.form.value.dateMesure, 'yyyy-MM-dd'),
      idAnimal: this.activeAnimal?.id,
    };

    this.store.dispatch(clientActions.updatePoids({ newPoids: objPoids }));
    this.form.setValue({ poids: 0, dateMesure: this.datePipe.transform(new Date(), 'yyyy-MM-dd') });
  }

  delete(poids: Poids) {
    this.alertController
      .create({
        header: 'Êtes-vous sûr de vouloir supprimer cette mesure ?',
        buttons: [
          {
            text: 'Non',
            handler: () => {},
          },
          {
            text: 'Oui',
            handler: () => {
              this.store.dispatch(clientActions.deletePoids({ newPoids: poids }));
            },
          },
        ],
      })
      .then(res => {
        res.present();
      });
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  seeCourbe() {
    this.showCourbe = !this.showCourbe;
  }

  ngOnDestroy() {
    this.store.dispatch(clientActions.resetPoids());
    this.destroy$.next();
    this.destroy$.complete();
  }
}
